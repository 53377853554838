<template>
  <show-release-date-error
    v-if="showReleaseDateError"
    :release-date="releaseDate"
    @go-back-to-login="showLoginForm"
  />

  <v-row v-else class="d-flex justify-center align-center">
    <v-col cols="12" no-gutters>
      <v-row no-gutters class="welcome-text">
        {{ $t('login.welcome_back') }}
      </v-row>
      <v-row no-gutters class="welcome-secundary-text">
        {{ $t('login.welcome_message') }}
      </v-row>
      <v-row no-gutters class="options-label pt-4">
        {{ $t('login.email') }}
      </v-row>
      <v-text-field
        v-model="loginFormEmail"
        color="#39af49"
        dense
        hide-details
        outlined
        solo
        :placeholder="$t('login.digit_email')"
      >
        <template #append>
          <font-awesome-icon class="icon-outlined" icon="envelope" />
        </template>
      </v-text-field>
      <v-row no-gutters class="options-label pt-4">
        {{ $t('login.password') }}
      </v-row>
      <v-text-field
        v-model="password"
        :placeholder="$t('login.digit_password')"
        solo
        outlined
        dense
        hide-details
        color="#39af49"
        type="password"
        @keyup.enter="login"
      >
        <template #append>
          <font-awesome-icon class="icon-outlined" icon="key" />
        </template>
      </v-text-field>
      <v-row no-gutters class="pt-7">
        <v-btn
          class="text-none platform-access-button"
          width="100%"
          :loading="isLoading"
          @click="login"
        >
          <span class="platform-access">
            {{ $t('login.platform_access') }}
          </span>
          <font-awesome-icon class="icon-access" icon="sign-in-alt" />
        </v-btn>
      </v-row>
      <v-row no-gutters class="pt-6 align-center justify-center" offset-md="3">
        <a class="text-link text-none" @click.prevent="goToRecoveryPassword">
          {{ $t('login.forgot_password') }}
        </a>
        <font-awesome-icon class="icon-link" icon="key" />
      </v-row>
      <v-row no-gutters class="error-message">
        <alert-login-error />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ShowReleaseDateError from '@/modules/auth/components/ShowReleaseDateError.vue'
import AlertLoginError from '@/modules/auth/components/AlertLoginError.vue'
import Cookies from 'js-cookie'
import { mapActions } from 'vuex'

export default {
  name: 'LoginForm',

  components: {
    AlertLoginError,
    ShowReleaseDateError,
  },

  props: {
    email: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      isLoading: false,
      loginAPIResponse: {},
      password: '',
      showReleaseDateError: false,
      releaseDate: '',
    }
  },

  computed: {
    loginFormEmail: {
      get() {
        return this.email
      },
      set(value) {
        this.$emit('change-email', value)
      },
    },
  },

  methods: {
    ...mapActions('apiManager', ['resetApiErrorMessage']),

    showLoginForm() {
      this.showReleaseDateError = false
    },

    async getAccountSelected(accountId) {
      try {
        const response = await this.$api.auth.selectAccount(accountId)

        if (response && response.data.token) {
          Cookies.set('token', response.data.token)
        }

        this.$emit('logged-in', this.loginAPIResponse)
        this.$router.push({
          path: '/my_farm',
        })
      } catch (error) {
        console.error(error)
      }
    },

    goToRecoveryPassword() {
      this.resetApiErrorMessage()
      this.$emit('recover-password', true)
    },

    async login() {
      try {
        this.isLoading = true
        const response = await this.$api.auth.login(this.email, this.password)

        if (response && response.data.code === 102) {
          this.showReleaseDateError = true
          this.releaseDate = response.data.context.release

          return
        }

        if (response.data) {
          this.loginAPIResponse = response.data
          this.resetApiErrorMessage()
          const accounts = response.data.accounts
          Cookies.set('token', response.data.token)
          Cookies.set(
            'first_login_full_plan',
            response.data.is_first_login_full_plan
          )

          if (accounts.length === 1) {
            await this.getAccountSelected(accounts[0].id)
          } else {
            this.$emit('logged-in', this.loginAPIResponse)
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome-text {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.welcome-secundary-text {
  color: #51594f;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.options-label {
  color: #253521;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: -3px;
}
.icon-outlined {
  color: #788476;
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.platform-access-button {
  background-color: #39af49 !important;
}
.platform-access {
  color: #fff;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
}
.icon-access {
  color: #fff !important;
  font-size: 10px;
  margin-left: 5px;
}
.text-link {
  color: #788476;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.icon-link {
  color: #5b6459;
  font-size: 10px;
  margin-left: 5px;
}
.error-message {
  padding-top: 28px;
  height: 72px;
}
</style>
