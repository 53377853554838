<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      lg="4"
      md="5"
      sm="12"
      class="px-6 px-md-12 py-4 d-flex flex-column"
    >
      <v-row class="header-login d-flex justify-space-between">
        <v-col class="mx-0" md="5">
          <img
            alt="digifarmz-icon"
            src="img/logos/logo-digifarmz.svg"
            width="160px"
          />
        </v-col>
        <v-col md="6" class="header-login_language-selector mx-0">
          <language-selector />
        </v-col>
      </v-row>
      <login-form
        v-if="showLoginForm"
        :email="email"
        @change-email="changeEmail"
        @logged-in="userLoggedIn"
        @recover-password="userIsRecoveringPassword"
      />
      <account-selector v-else-if="showAccountSelector" :accounts="accounts" />
      <recover-password-form
        v-else-if="showRecoverPasswordForm"
        :email="email"
        @change-email="changeEmail"
        @recover-password="userIsRecoveringPassword"
      />
      <footer-support />
    </v-col>
    <v-col
      v-if="!isMobile"
      cols="12"
      lg="8"
      md="7"
      sm="0"
      class="farmer-img__wrapper"
    >
      <img
        class="login-side-image"
        alt="farmer"
        src="img/backgrounds/img-farmer-login-1920.webp"
        srcset="
          img/backgrounds/img-farmer-login-1264.webp 1264w,
          img/backgrounds/img-farmer-login-1920.webp 1920w
        "
        sizes="
          (max-width: 1264px) 1264px,
          (max-width: 1920px) 1920px,
          1920px
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import FooterSupport from '@/modules/auth/components/FooterSupport.vue'
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import LoginForm from '@/modules/auth/components/LoginForm.vue'

const RecoverPasswordForm = () =>
  import('@/modules/auth/components/RecoverPasswordForm.vue')
const AccountSelector = () =>
  import('@/modules/auth/components/AccountSelector.vue')

export default {
  name: 'Auth',

  mixins: [LayoutMixin],

  components: {
    FooterSupport,
    LanguageSelector,
    LoginForm,
    AccountSelector,
    RecoverPasswordForm,
  },

  data() {
    return {
      accounts: [],
      email: '',
      recoveringPassword: false,
      user: {},
    }
  },

  computed: {
    hasUser() {
      return !!Object.keys(this.user).length
    },
    showAccountSelector() {
      return this.hasUser && this.accounts?.length > 1
    },
    showLoginForm() {
      return !this.hasUser && !this.recoveringPassword
    },
    showRecoverPasswordForm() {
      return !this.hasUser && this.recoveringPassword
    },
  },

  methods: {
    changeEmail(value) {
      this.email = value
    },
    userIsRecoveringPassword(value) {
      this.recoveringPassword = value
    },
    userLoggedIn(data) {
      this.accounts = data.accounts
      this.user = data
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep input:-webkit-autofill,
::v-deep input:-webkit-autofill:hover,
::v-deep input:-webkit-autofill:focus,
::v-deep input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #000000 !important;
  transition: background-color 5000s ease-in-out 0s !important;
  padding-top: 14px !important;
}
::v-deep .v-list-item__icon:first-child {
  margin-right: 8px !important;
  margin-top: 20px;
}
::v-deep .v-text-field input {
  padding: 13px 0 8px;
  color: #5b6459;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
::v-deep .v-text-field--outlined fieldset {
  top: 0 !important;
}
::v-deep .v-list-item__content {
  flex: 15 1;
}
::v-deep .v-input__control {
  height: 0px;
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
::v-deep hr {
  background-color: #d6d6d6;
  border-color: #d6d6d6;
  margin-left: 0px;
  margin-right: 0px;
  opacity: 0.4;
}
.header-login,
.header-login_language-selector {
  height: 74px;
  max-height: 74px;
}
.farmer-img__wrapper {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  margin: 12px 0px !important;

  .login-side-image {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }
}
</style>
